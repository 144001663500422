import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PageProps, navigate } from 'gatsby';
import Layout from 'components/_layout';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import Input from 'components/Input';
import Select from 'components/Select';
import Mask from 'components/Input/mask';
import Button from 'components/Button';
import { FiLock, FiUser } from 'react-icons/fi';
import {
  FaAt,
  FaBirthdayCake,
  FaIdCard,
  FaIdCardAlt,
  FaMapMarkedAlt,
  FaMobileAlt,
  FaPhoneAlt,
} from 'react-icons/fa';
import api from 'services/api';
import queryString from 'query-string';

import { createCart, ICartDTO } from 'components/Cart';
import {
  PageHolder,
  ContentHolder,
  FormHolder,
} from '../styles/pages/nova-conta';

interface FormData {
  name: string;
  cpf: string;
  rg: string;
  dataNascimento: Date;
  telefone: string;
  celular: string;
  email: string;
  password: string;
  cep?: string;
  endereco?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  estado?: string;
  cidade?: string;
}

const Contact = (props: PageProps) => {
  const { location } = props;

  const [estadosOptions, setEstadosOptions] = useState([
    { value: 'acre', label: 'Acre' },
    { value: 'alagoas', label: 'Alagoas' },
    { value: 'amazonas', label: 'Amazonas' },
    { value: 'amapá', label: 'Amapá' },
    { value: 'bahia', label: 'Bahia' },
    { value: 'ceará', label: 'Ceará' },
    { value: 'distrito federal', label: 'Distrito Federal' },
    { value: 'espírito santo', label: 'Espírito Santo' },
    { value: 'goiás', label: 'Goiás' },
    { value: 'maranhão', label: 'Maranhão' },
    { value: 'minas gerais', label: 'Minas Gerais' },
    { value: 'mato grosso do sul', label: 'Mato Grosso do Sul' },
    { value: 'mato grosso', label: 'Mato Grosso' },
    { value: 'pará', label: 'Pará' },
    { value: 'paraíba', label: 'Paraíba' },
    { value: 'pernambuco', label: 'Pernambuco' },
    { value: 'piauí', label: 'Piauí' },
    { value: 'paraná', label: 'Paraná' },
    { value: 'rio de janeiro', label: 'Rio de Janeiro' },
    { value: 'rio grande do norte', label: 'Rio Grande do Norte' },
    { value: 'rondônia', label: 'Rondônia' },
    { value: 'roraima', label: 'Roraima' },
    { value: 'rio grande do sul', label: 'Rio Grande do Sul' },
    { value: 'santa catarina', label: 'Santa Catarina' },
    { value: 'sergipe', label: 'Sergipe' },
    { value: 'são paulo', label: 'São Paulo' },
    { value: 'tocantins', label: 'Tocantins' },
  ]);

  const queryParams = queryString.parse(location.search);

  const { redirect } = queryParams;

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: FormData, { reset }) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          cpf: Yup.string()
            .required('CPF é obrigatório')
            .matches(
              // eslint-disable-next-line no-useless-escape
              /^\d{3}\x2E\d{3}\x2E\d{3}\x2D\d{2}/,
              'O cpf deve estar no formato correto'
            ),
          rg: Yup.string().required('RG é obrigatório'),
          dataNascimento: Yup.string().required(
            'Data de nascimento é obrigatória'
          ),
          telefone: Yup.string().required('Telefone é obrigatório'),
          celular: Yup.string(),
          email: Yup.string()
            .email('Insira um e-mail válido')
            .required('E-mail obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'As senhas devem ser iguais'
          ),
          cep: Yup.string(),
          endereco: Yup.string(),
          numero: Yup.string(),
          complemento: Yup.string(),
          bairro: Yup.string(),
          estado: Yup.string(),
          cidade: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .post('/users/endereco', data)
          .then(res => {
            toast.success('✔️ Registro efetuado com sucesso.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            try {
              setLoading(true);

              const { email, password } = data;

              api
                .post('/sessions', { email, password })
                .then(res2 => {
                  const { token, user } = res2.data;

                  localStorage.setItem('@FGLivraria:token', token);
                  localStorage.setItem(
                    '@FGLivraria:user',
                    JSON.stringify(user)
                  );

                  const cart = localStorage.getItem('@FGLivraria:cart');
                  if (cart) {
                    const cartParsed: ICartDTO = JSON.parse(cart);
                    cartParsed.userId = user.id;
                    localStorage.setItem(
                      '@FGLivraria:cart',
                      JSON.stringify(cartParsed)
                    );
                  } else {
                    createCart();
                  }

                  api.defaults.headers.authorization = `Bearer ${token}`;

                  toast.success(
                    '✔️ Login efetuado com sucesso. Redirecionando para outra página...',
                    {
                      position: 'top-right',
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                  reset();
                  setTimeout(() => {
                    if (redirect === '321') {
                      navigate('/finalizar-compra/');
                    } else if (
                      redirect === '123' &&
                      localStorage.getItem('@FGLivraria:cart')
                    ) {
                      navigate('/carrinho');
                    } else {
                      navigate('/');
                    }
                  }, 3000);
                })
                .catch(err => {
                  toast.error('❌ Erro ao fazer login. Tente novamente.', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  console.error(`Erro: ${err}`);
                });
            } catch (err) {
              console.error(`Erro: ${err}`);

              toast.error('❌ Erro ao fazer login. Tente novamente.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } finally {
              setLoading(false);
            }
          })
          .catch(err => {
            toast.error('❌ Erro ao fazer o registro. Tente novamente.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        console.error(`Erro: ${err}`);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          let error = '❌ Erro ao fazer o registro. Tente novamente.';

          if (errors.name) {
            error = `❌ ${errors.name}`;
          } else if (errors.cpf) {
            error = `❌ ${errors.cpf}`;
          } else if (errors.rg) {
            error = `❌ ${errors.rg}`;
          } else if (errors.dataNascimento) {
            error = `❌ ${errors.dataNascimento}`;
          } else if (errors.telefone) {
            error = `❌ ${errors.telefone}`;
          } else if (errors.celular) {
            error = `❌ ${errors.celular}`;
          } else if (errors.email) {
            error = `❌ ${errors.email}`;
          } else if (errors.password) {
            error = `❌ ${errors.password}`;
          } else if (errors.confirmPassword) {
            error = `❌ ${errors.confirmPassword}`;
          } else if (errors.cep) {
            error = `❌ ${errors.cep}`;
          } else if (errors.endereco) {
            error = `❌ ${errors.endereco}`;
          } else if (errors.numero) {
            error = `❌ ${errors.numero}`;
          } else if (errors.complemento) {
            error = `❌ ${errors.complemento}`;
          } else if (errors.bairro) {
            error = `❌ ${errors.bairro}`;
          } else if (errors.estado) {
            error = `❌ ${errors.estado}`;
          } else if (errors.cidade) {
            error = `❌ ${errors.cidade}`;
          }

          toast.error(error, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          return;
        }

        toast.error('❌ Erro ao fazer o registro. Tente novamente.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setLoading(false);
      }
    },
    [getValidationErrors]
  );

  return (
    <Layout pathname={location.pathname}>
      <PageHolder>
        <h1>Nova conta, faça seu registro!</h1>
        <span className="obrigatorio">
          Campos com * do lado, são obrigatórios
        </span>
        <ContentHolder>
          <FormHolder>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <label htmlFor="name">Nome*:</label>
              <Input
                icon={FiUser}
                iconSize={25}
                id="name"
                name="name"
                placeholder="Digite seu nome"
              />
              <label htmlFor="cpf">CPF*:</label>
              <Mask
                mask="999.999.999-99"
                icon={FaIdCardAlt}
                iconSize={25}
                id="cpf"
                name="cpf"
                placeholder="Digite seu cpf"
              />
              <label htmlFor="rg">RG*:</label>
              <Input
                icon={FaIdCard}
                iconSize={25}
                id="rg"
                name="rg"
                placeholder="Digite seu rg"
              />
              <label htmlFor="dataNascimento">Data de nascimento*:</label>
              <Input
                type="date"
                icon={FaBirthdayCake}
                iconSize={25}
                id="dataNascimento"
                name="dataNascimento"
                placeholder="Digite sua data de nascimento"
              />

              <hr />

              <label htmlFor="email">Telefone*:</label>
              <Mask
                mask="(99) 9999-9999"
                icon={FaPhoneAlt}
                iconSize={25}
                id="telefone"
                name="telefone"
                placeholder="Digite seu telefone"
              />
              <label htmlFor="celular">Celular:</label>
              <Mask
                mask="(99) 99999-9999"
                icon={FaMobileAlt}
                iconSize={25}
                id="celular"
                name="celular"
                placeholder="Digite seu celular"
              />
              <label htmlFor="email">E-mail*:</label>
              <Input
                icon={FaAt}
                iconSize={25}
                id="email"
                name="email"
                placeholder="Digite seu e-mail"
              />
              <label htmlFor="password">Senha*:</label>
              <Input
                icon={FiLock}
                iconSize={25}
                id="password"
                name="password"
                type="password"
                placeholder="Digite sua senha"
              />
              <label htmlFor="confirmPassword">Confirme sua senha*:</label>
              <Input
                icon={FiLock}
                iconSize={25}
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Digite sua senha novamente"
              />

              <hr />

              <label htmlFor="cep">CEP:</label>
              <Mask
                mask="99999-999"
                icon={FaMapMarkedAlt}
                iconSize={25}
                id="cep"
                name="cep"
                placeholder="Digite seu cep"
              />
              <label htmlFor="endereco">Endereço:</label>
              <Input
                icon={FaMapMarkedAlt}
                iconSize={25}
                id="endereco"
                name="endereco"
                placeholder="Digite seu endereco"
              />
              <label htmlFor="endereco">Número:</label>
              <Input
                icon={FaMapMarkedAlt}
                iconSize={25}
                id="numero"
                name="numero"
                placeholder="Digite seu numero"
              />
              <label htmlFor="endereco">Complemento:</label>
              <Input
                icon={FaMapMarkedAlt}
                iconSize={25}
                id="complemento"
                name="complemento"
                placeholder="Digite seu complemento"
              />
              <label htmlFor="endereco">Bairro:</label>
              <Input
                icon={FaMapMarkedAlt}
                iconSize={25}
                id="bairro"
                name="bairro"
                placeholder="Digite seu bairro"
              />
              <label htmlFor="endereco">Estado (UF):</label>
              <Select
                options={estadosOptions}
                icon={FaMapMarkedAlt}
                iconSize={25}
                id="estado"
                name="estado"
                placeholder="Selecione seu estado"
              />
              <label htmlFor="endereco">Cidade:</label>
              <Input
                icon={FaMapMarkedAlt}
                iconSize={25}
                id="cidade"
                name="cidade"
                placeholder="Digite sua cidade"
              />

              <Button loading={loading} type="submit">
                CADASTRAR
              </Button>
            </Form>
          </FormHolder>
        </ContentHolder>
      </PageHolder>
    </Layout>
  );
};

export default Contact;
