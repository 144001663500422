import styled from 'styled-components';

export const PageHolder = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 50px;

    color: var(--main-bg);
    font-size: 32px;
    text-align: center;
  }
  span.obrigatorio {
    margin-top: 10px;
  }
`;

export const ContentHolder = styled.div`
  width: 60%;
  height: 100%;

  margin-top: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

  border: 2px solid var(--main-color);

  @media (max-width: 768px) {
    & {
      flex-direction: column;
      width: 90%;
    }
  }
`;

export const FormHolder = styled.div`
  width: 85%;
  height: 100%;

  padding: 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  form {
    width: 100%;
    label {
      font-size: 18px;
    }
    hr {
      border: none;
      background: rgba(255, 255, 255, 0);
      height: 50px;
    }
    .inputHolder,
    .selectHolder {
      margin: 5px 0 15px;
      border-radius: 10px;
    }
    .selectHolder {
      padding: 20px 16px;
    }

    button[type='submit'] {
      margin-top: 10px;

      height: 100%;
      border: none;
      background-color: var(--main-color);
      color: #000;
      font-weight: 600;
      font-size: 18px;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);

      padding: 10px 50px;
      border-radius: 30px;

      text-align: center;
    }
  }

  @media (max-width: 768px) {
    & {
      width: 90%;
      height: 50%;
      margin: 30px 0 0;
    }
  }
`;
